const Layout = (props) => {
  let classes = [
    "container-fluid",
    "d-flex",
    "flex-column",
    "flex-grow-1",
    "position-relative",
  ];

  if (props.className) {
    classes.push(props.className);
  }

  return <div className={classes.join(" ")}>{props.children}</div>;
};

export default Layout;
