import { createSlice } from "@reduxjs/toolkit";

const scannerSlice = createSlice({
  name: "scanner",
  initialState: { isLoading: false, barcode: "" },
  reducers: {
    submit(state) {
      state.isLoading = true;
    },
    error(state) {
      state.isLoading = false;
    },
    change(state, action) {
      state.barcode = action.payload.barcode;
    },
    reset(state, action) {
      state.isLoading = false;
      state.barcode = "";
    },
  },
});

export const scannerActions = scannerSlice.actions;

export const selectScanSlice = (state) => state.scanner;

export default scannerSlice.reducer;
