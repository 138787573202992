import Layout from "../layouts/Layout";

import image from "../assets/images/not-found.png";

const NotFound = () => {
  return (
    <Layout>
      <div className="row align-self-center align-content-center justify-content-center flex-grow-1 flex-column">
        <div className="text-center">
          <img src={image} alt="Not found" height={60} className="mb-2" />
          <h3>No store was found associated with your IP address.</h3>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
