import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStore } from "../api/api";

export const loadStore = createAsyncThunk("store/loadStore", async () => {
  const store = await getStore();
  return store;
});

const storeSlice = createSlice({
  name: "store",
  initialState: { status: "idle", value: null, error: null },
  reducers: {},
  extraReducers: {
    [loadStore.pending]: (state, action) => {
      state.status = "loading";
    },
    [loadStore.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.value = action.payload;
    },
    [loadStore.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const selectStore = (state) => state.store;

export default storeSlice.reducer;
