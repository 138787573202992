import { Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import brandLogo from "../assets/images/ScriptCardsLogoWhite.png";
import { SITE_NAME } from "../constants";
import { selectStore } from "../store/storeSlice";

const AppNavbar = () => {
  const { value: store } = useSelector(selectStore);

  let storeContent = null;

  if (store) {
    storeContent = <Navbar.Text className="ml-auto">{store.name}</Navbar.Text>;
  }

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Link className="navbar-brand" to="/">
        <img src={brandLogo} alt={`${SITE_NAME} logo`} height={30} />
      </Link>
      {storeContent}
    </Navbar>
  );
};

export default AppNavbar;
