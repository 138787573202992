import Layout from "../layouts/Layout";

import errorImage from "../assets/images/warning.png";

const Error = (props) => {
  return (
    <Layout>
      <div className="row align-self-center align-content-center justify-content-center flex-grow-1 flex-column">
        <div className="text-center">
          <img src={errorImage} alt="Error" height={60} className="mb-2" />
          <h3>{props.message}!</h3>
        </div>
      </div>
    </Layout>
  );
};

export default Error;
