import React from 'react'
import PropTypes from 'prop-types'

const Loader = props => {
  let classes = ["align-self-stretch", "d-flex", "align-items-center ", "justify-content-center", "flex-column"];
  let styles = {};

  if (props.height) {
    styles = {
      height: props.height,
    };
  } else {
    classes.push("flex-grow-1");
  }

  return (
    <div className={classes.join(" ")} style={styles}>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

Loader.propTypes = {
  height: PropTypes.number,
}

export default Loader
