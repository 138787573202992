import React from "react";
import PropTypes from "prop-types";

import classes from "./Message.module.css";

const Message = (props) => {
  return (
    <div className={[classes[props.type], classes.message].join(" ")}>
      {props.message}
    </div>
  );
};

Message.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default Message;
