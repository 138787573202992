import Loader from "../components/Loader";
import Layout from "../layouts/Layout";

const Home = () => {
  return (
    <Layout>
      <Loader />
    </Layout>
  );
};

export default Home;
