import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { API_BASE_URL } from "../constants";
import { messageListActions } from "../store/messageListSlice";
import { scannerActions } from "../store/scannerSlice";
import BarcodeInput from "./BarcodeInput";

const BarcodeInputForm = () => {
  const dispatch = useDispatch();

  const barcodeInputSubmitHandler = async (barcode) => {
    try {
      dispatch(scannerActions.submit());

      const response = await axios.post(
        `${API_BASE_URL}/print?barcode=${barcode}`
      );
      const data = response.data;
      const message = `${data.product.drugAndDrugForm} sent for printing`;
      dispatch(
        messageListActions.add({
          id: Date.now(),
          type: "success",
          message: message,
        })
      );

      dispatch(scannerActions.reset());
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        "Server error";

      dispatch(
        messageListActions.add({
          id: Date.now(),
          type: "error",
          message: message,
        })
      );

      dispatch(scannerActions.error());
    }
  };

  const barcodeInputChangeHandler = (event) => {
    dispatch(scannerActions.change({ barcode: event.target.value }));
  };

  return (
    <div className="mx-auto my-5" style={{ width: "100%", maxWidth: "600px" }}>
      <BarcodeInput
        placeholder="Barcode"
        onSubmit={barcodeInputSubmitHandler}
        onChange={barcodeInputChangeHandler}
        disabled={false}
      />
    </div>
  );
};

export default BarcodeInputForm;
