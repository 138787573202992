import React from "react";
import { useSelector } from "react-redux";
import { selectMessageList } from "../../store/messageListSlice";
import Message from "./Message";

import classes from "./MessageList.module.css";

const MessageList = () => {
  const { messages } = useSelector(selectMessageList);

  return (
    <div className={classes.messageList}>
      <div className={classes.title}>
        <h2 className="h6">Updates</h2>
      </div>
      <div className={classes.list}>
        {messages.map((message) => {
          return (
            <Message
              key={message.id}
              message={message.message}
              type={message.type}
              id={message.id}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MessageList;
