import { createSlice } from "@reduxjs/toolkit";

const messageListSlice = createSlice({
  name: "messages",
  initialState: { messages: [] },
  reducers: {
    add(state, action) {
      state.messages = [action.payload].concat(state.messages);
    },
  },
});

export const messageListActions = messageListSlice.actions;

export const selectMessageList = (state) => state.messageList;

export default messageListSlice.reducer;
