import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectScanSlice } from "../store/scannerSlice";

const BarcodeInput = (props) => {
  const state = useSelector(selectScanSlice);

  const formSubmitHandler = (event) => {
    event.preventDefault();
    props.onSubmit(state.barcode);
  };

  const renderButton = () => {
    if (state.isLoading) {
      return (
        <button
          type="submit"
          className="btn btn-primary btn-lg d-block d-md-inline flex-grow-1 flex-md-grow-0"
          disabled
        >
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      );
    } else {
      return (
        <button
          type="submit"
          className="btn btn-primary btn-lg d-block d-md-inline flex-grow-1 flex-md-grow-0"
        >
          Submit
        </button>
      );
    }
  };

  return (
    <form className="form-inline" onSubmit={formSubmitHandler}>
      <label className="sr-only" htmlFor="barcode-input">
        Enter barcode
      </label>
      <input
        id="barcode-input"
        className="form-control form-control-lg flex-grow-1 mr-sm-2 mb-2 mb-md-0"
        type="text"
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={state.barcode}
        disabled={state.isLoading}
      />
      {renderButton()}
    </form>
  );
};

BarcodeInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default BarcodeInput;
