import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import AppNavbar from "./components/AppNavbar";
import Error from "./pages/Error";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Scan from "./pages/Scan";
import { selectStore, loadStore } from "./store/storeSlice";

const Wrapper = (props) => {
  return (
    <Switch>
      <Route exact path="/">
        <AppNavbar />
        {props.children}
      </Route>
      <Route path="/">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

function App() {
  const { status, value: store, error: storeError } = useSelector(selectStore);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadStore());
  }, [dispatch]);

  let content = null;

  switch (status) {
    case "idle":
      content = null;
      break;
    case "loading":
      content = <Home />;
      break;
    case "failed":
      content = <Error message={storeError} />;
      break;
    default:
      if (store) {
        content = <Scan />;
      } else {
        content = <NotFound />;
      }
  }

  return <Wrapper>{content}</Wrapper>;
}

export default App;
