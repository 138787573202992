import { configureStore } from "@reduxjs/toolkit";
import messageListReducer from "./messageListSlice";
import scannerReducer from "./scannerSlice";
import storeReducer from "./storeSlice";

export default configureStore({
  reducer: {
    store: storeReducer,
    scanner: scannerReducer,
    messageList: messageListReducer,
  },
});
