import BarcodeInputForm from "../components/BarcodeInputForm";
import MessageList from "../components/Messages/MessageList";
import Layout from "../layouts/Layout";

import classes from "./Scan.module.css";

const Scan = () => {
  return (
    <Layout className={classes.scanPage}>
      <div className="row align-self-stretch align-content-start align-content-md-stretch flex-grow-1 flex-column flex-md-row">
        <div className="col-md d-flex justify-content-center flex-column">
          <BarcodeInputForm />
        </div>
        <div
          className={["col-md", "d-flex", "flex-grow-1", classes.sidebar].join(
            " "
          )}
        >
          <MessageList />
        </div>
      </div>
    </Layout>
  );
};

export default Scan;
